import { RefObject } from 'react'
import { AssetRenderer } from '@/components'
import { ContentItemInterface } from '../../../../interfaces/contentItem'
import ArrowRight from '../../../../public/images/icons/arrow-right.svg'
import { 
  Container,
  Image,
  ContentContainer,
  TitleHolder,
  Title,
  Description,
  LinkContainer,
  AssetWrapper,
  StyledButton,
} from './IconAndCopyInCard.styles'
import { SectionThemeKey } from '@/interfaces/sectionThemeKey'

interface ITitleProps {
  forwardedRef?: RefObject<HTMLParagraphElement>
  height?: number
}

const IconAndCopyInCard: React.FC<ContentItemInterface & ITitleProps> = (props) => {
  const {
    title,
    description,
    image,
    buttonLink,
    buttonEventLabel,
    openInNewTab,
    forwardedRef,
  } = props
  const buttonText = props.buttonText?.trim() || ''
  const height = forwardedRef?.current?.clientHeight

  return (
    <Container>
      {image && (
        <AssetWrapper>
          <AssetRenderer asset={image} ImageComponent={Image} />
        </AssetWrapper>
      )}
      <ContentContainer>
        {
          title &&
          <TitleHolder minHeight={height}>
            <Title ref={forwardedRef}>{title}</Title>
          </TitleHolder>
        }
        <Description>{description}</Description>
        {
          buttonLink && (
            <LinkContainer>
              <StyledButton
                variant='link'
                href={buttonLink}
                eventLabel={buttonEventLabel}
                openInNewTab={openInNewTab}
                icon={ArrowRight}
                buttonTheme={SectionThemeKey.Light}
              >
                {buttonText || 'Learn more'}
              </StyledButton>
            </LinkContainer>
          )
        }
      </ContentContainer>
    </Container>
  )
}

export default IconAndCopyInCard
