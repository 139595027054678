import styled from 'styled-components'
import { TextStyles } from '@/constants'
import Typography from '@/components/Typography'
import { styledText } from '@/components/Typography/Typography.styles'
import Button from '@/components/Button'

export const Image = styled.img`
  object-fit: contain;
  object-position: top left;
`

export const StyledButton = styled(Button)`
  padding: 0;
`

export const AssetWrapper = styled.div`
  ${Image}, > .dotlottie-container {
    display: block;  
    width: 100%;
    width: 45px;
    height: 45px;
    margin-bottom: 12px;
    flex: 0 0 auto;
  }
`

export const TitleHolder = styled.div<{ minHeight: number | undefined }>`
  margin-bottom: 12px;
  height: ${props => props.minHeight ? `${props.minHeight}px` : 'auto'};
`

export const Title = styled(Typography).attrs({
  type: 'p',
  variant: TextStyles['Heading 2'],
})`
  color: ${({ theme }) => theme.colors.darkBlue};
  min-height: 1px;
  word-break: break-word;
`

export const Description = styled(Typography).attrs({
  variant: TextStyles['Small Paragraph Text'],
})`
  flex: auto;
  word-break: break-word;
  color: ${({ theme }) => theme.colors.lightGray};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Large Paragraph Text']]}
  }
`

export const LinkContainer = styled.div`
  display: block;
  margin-top: 10px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: 20px;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 280px;
  max-width: 280px;
  min-height: 339px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  border: 1px solid #D1D5DB;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: auto;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
